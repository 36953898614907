import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private isLoaderShown = false;
  private loader: HTMLIonLoadingElement;
  private modalMap: Map<string, boolean> = new Map<string, boolean>();

  constructor(
    public alertController: AlertController,
    public toastController: ToastController,
    private loadingCtrl: LoadingController,
    private modalController: ModalController
  ) {

  }

  async presentLoading(mensaje) {
    if (this.isLoadingPresent) {
      this.setLoaderText(mensaje);
      return Promise.resolve();
    }
    let initTime;
    let finishTime;
    this.loader = await this.loadingCtrl.create({
      message: mensaje,
      translucent: true,
      spinner: null,
      cssClass: 'app-loader',
      animated: true
    });
    this.loader.onDidDismiss().then(() => {
      this.isLoaderShown = false;
      this.loader = null;
      finishTime = new Date().getTime();
    });

    return this.loader.present().then(() => {
      this.isLoaderShown = true;
      initTime = new Date().getTime();
    });
  }

  async dismissLoading() {
    return await this.loadingCtrl.dismiss().then(() => {
      this.isLoaderShown = false;
    }).catch(() => {
      this.isLoaderShown = false;
    });
  }

  setLoaderText(text: string) {
    if (this.loader) {
      this.loader.message = text;
    }
  }

  get isLoadingPresent() {
    return this.isLoaderShown;
  }

  presentAlert(message, header): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header,
        message,
        buttons: [{
          text: 'Aceptar',
          handler: () => {
          }
        }]
      });
      alert.onDidDismiss().then(() => {
        resolve(true);
      });
      await alert.present();
    });
  }

  presentConfirm(title, message, aceptar?, cancelar?) {
    return new Promise((resolve) => {
      this.alertController.create({
        header: title,
        message,
        buttons: [
          {
            text: cancelar ? cancelar : 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false);
            }
          }, {
            text: aceptar ? aceptar : 'Aceptar',
            handler: () => {
              resolve(true);
            }
          }
        ]
      }).then((alert) => alert.present());
    });
  }

  async presentToast(messageT, durationT?, positionT?) {
    const toast = await this.toastController.create({
      color: 'warning',
      message: messageT,
      position: positionT ? positionT : 'bottom',
      duration: durationT ? durationT === 'short' ? 3000 : 5000 : 3000
    });
    await toast.present();
  }

  async createModal(page: any, animated: boolean = true, data?: any) {
    if (this.modalMap.has(page.name)) {
      return Promise.reject('modal already open');
    }
    console.log('open page>>>', page.name);
    this.modalMap.set(page.name, true);
    const modal = await this.modalController.create({
      component: page,
      componentProps: data,
      backdropDismiss: false,
      keyboardClose: false,
      showBackdrop: false,
      swipeToClose: false,
      animated: animated,
      mode: 'ios',
      presentingElement: document.getElementById('app-router')
    });
    modal.onDidDismiss().then(() => {
      this.modalMap.delete(page.name);
      console.log('close page>>>', page.name);
    });
    return modal;
  }

  async closeModal(data?) {
    this.modalController.dismiss(data);
  }

  getLocalId() {
    const letras = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const randomLetra1 = letras[Math.floor(Math.random() * letras.length)];
    const randomLetra2 = letras[Math.floor(Math.random() * letras.length)];
    const randomLetra3 = letras[Math.floor(Math.random() * letras.length)];
    return `${randomLetra1}${Math.round(new Date().getTime())}${randomLetra2}${Math.round(Math.random() * 10000)}${randomLetra3}`;
  }

  getRandomNumber() {
    return Math.floor(Math.random() * 10) + new Date().getTime() + Math.floor(Math.random() * 100);
  }

  // get all values of the formGroup, loop over them
  // then mark each field as touched
  markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach((control: any) => {
      //// console.log(control);
      control.markAsTouched();
      control.markAsDirty();
      if (control.controls) {
        if (Array.isArray(control.controls)) {
          control.controls.forEach(c => this.markFormGroupTouched(c));
        } else {
          this.markFormGroupTouched(control);
        }
      }
    });
  }

  getFormGroupErrors(formGroup: FormGroup, errors: any = {}) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.touched && control.dirty) {
        errors[field] = control.errors;
      } else if (control instanceof FormGroup) {
        errors[field] = this.getFormGroupErrors(control);
      }
    });
    return errors;
  }

  copyWwwFolder() {
    // window.resolveLocalFileSystemURL(`${cordova.file.applicationDirectory}`,
    //   (parentEntry: any) => {
    //     console.log('parentEntry>>>>>', parentEntry);
    //     window.resolveLocalFileSystemURL(`${cordova.file.applicationDirectory}www`,
    //       (childEntry: any) => {
    //         console.log('childEntry>>>>>', childEntry);
    //         childEntry.removeRecursively((success) => {
    //           console.log('success>>>>', success);
    //         }, (error) => {
    //           console.log('error>>>>', error);
    //         });
    //         // childEntry.copyTo(parentEntry, 'www_copy', (success) => {
    //         //   console.log('success>>>>', success);
    //         // }, (error) => {
    //         //   console.log('error>>>>', error);
    //         // });
    //       }, (err) => {
    //         console.log(err);
    //       }
    //     );
    //   }, (err) => {
    //     console.log(err);
    //   }
    // );
  }

  cloneObj(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

}
