import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AppReadyService } from 'src/app/services/app-ready/app-ready.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DatabaseService } from 'src/app/services/database/database.service';
import { DeviceInfoService } from 'src/app/services/device-info/device-info.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public appReadyService: AppReadyService,
    public navigationService: NavigationService,
    public authService: AuthService,
    public deviceInfoService: DeviceInfoService,
    public databaseService: DatabaseService,
    public utilsService: UtilsService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.appReadyService.onAppReady().then(async () => {
      if (this.authService.isUserAuthenticated()) {
        if (state.url === '/home') {
          let permissions = [];
          let requestPermissions = null;
          try {
            requestPermissions = await this.databaseService.get('request_permissions');
          } catch (error) {
            console.error(error);
          }

          if (!requestPermissions) {
            try {
              permissions = await this.deviceInfoService.getPermissionsAuthorizationStatus();
            } catch (error) {
              console.error(error);
            }
          }
          console.log('requestPermissions>>>', requestPermissions);
          console.log('permissions>>>', permissions);
          console.log('state.url>>>', state.url);
          if (!requestPermissions && permissions.length > 0) {
            this.navigationService.navigate('root', ['/permissions']);
            return false;
          } else if (!this.validateUserProfile()) {
            this.utilsService.presentToast('Por favor complete el perfil.', 'short', 'bottom').finally(() => { });
            this.navigationService.navigate('root', ['/profile']);
            return false;
          } else if (!this.authService.getUser().last_rf_survey_date || localStorage.getItem('update_rfsurvey')) {
            this.utilsService.presentToast('Por favor conteste la encuesta.', 'short', 'bottom').finally(() => { });
            this.navigationService.navigate('root', ['/factor-riesgo']);
            return false;
          } else if (!this.authService.getUser().last_ad_survey_date || localStorage.getItem('update_adsurvey')) {
            this.utilsService.presentToast('Por favor conteste la encuesta.', 'short', 'bottom').finally(() => { });
            this.navigationService.navigate('root', ['/autodiagnostico']);
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        this.navigationService.navigate('root', ['/login']);
        return false;
      }
    });
  }

  validateUserProfile() {
    const user = this.authService.getUser();
    return user && user.identification && user.gender && user.birth_date && user.carge_type && user.city && user.state && user.socioeconomic_stratum && user.address.length > 0;
    // return user && user.identification && user.gender && user.birth_date && user.carge_type && user.city && user.state && user.socioeconomic_stratum && user.place && user.address.length > 0;
  }

}
