import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
// components
import { SelectDataComponent } from './components/select-data/select-data.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { PrivacyModalComponent } from './components/privacy-modal/privacy-modal.component';
import { SelectAddressComponent } from './components/select-address/select-address.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuPopoverComponent } from './components/menu-popover/menu-popover.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { MenuModalComponent } from './components/menu-modal/menu-modal.component';
import { SliderComponent } from './components/slider/slider.component';
import { SlideComponent } from './components/slide/slide.component';
import { ArrayValidator } from './directives/array.validator';
import { ClickEventDirective } from './directives/click-event.directive';
import { SetFocusDirective } from './directives/set-focus.directive';

@NgModule({
  declarations: [
    SelectDataComponent,
    SelectAddressComponent,
    PrivacyModalComponent,
    ToolbarComponent,
    MenuPopoverComponent,
    SuccessModalComponent,
    MenuModalComponent,
    SliderComponent,
    SlideComponent,
    ArrayValidator,
    ClickEventDirective,
    SetFocusDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    AgmCoreModule
  ],
  exports: [
    SelectDataComponent,
    SelectAddressComponent,
    PrivacyModalComponent,
    ToolbarComponent,
    MenuPopoverComponent,
    SuccessModalComponent,
    MenuModalComponent,
    SliderComponent,
    SlideComponent,
    ArrayValidator,
    ClickEventDirective,
    SetFocusDirective
  ],
  entryComponents: [
    SelectDataComponent,
    SelectAddressComponent,
    PrivacyModalComponent,
    ToolbarComponent,
    MenuPopoverComponent,
    SuccessModalComponent,
    MenuModalComponent,
    SliderComponent,
    SlideComponent
  ]
})
export class SharedModule { }
