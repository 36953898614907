import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { DeviceInfo } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  private isConnected = new BehaviorSubject<boolean>(true);

  constructor(
    public platform: Platform
  ) {
  }

  init() {
    this.platform.ready().then(async () => {
      this.isConnected.next(true);
      this.getUserPosition().catch(() => { }).finally(() => { });
    });
  }

  deviceInfo(): Promise<DeviceInfo> {
    return this.platform.ready().then(async () => {
      try {
        let deviceInfo = {};
        deviceInfo = {
          device_cordova: false,
          device_model: null,
          device_manufacturer: null,
          device_platform: 'web',
          device_os_version: null,
          device_uuid: null,
          device_is_virtual: null,
          app_version_code: environment.app_version_code,
          app_version_number: environment.app_version_number,
          app_package_name: environment.app_package_name,
          app_name: environment.app_name
        };
        return deviceInfo;
      } catch (error) {
        console.log(error);
        return null;
      }
    });
  }


  getPermissionsAuthorizationStatus() {
    return Promise.resolve([]);
  }

  get IsConnected() {
    return this.isConnected.value;
  }

  /**
   * Actualiza la variable que hay en coords a la variable global
   */
  getUserPosition(): Promise<any> {
    return this.platform.ready().then(async () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((currentPosition) => {
          resolve(currentPosition);
        }, (error) => {
          reject(error);
        }, { enableHighAccuracy: false, maximumAge: 1000 * 60, timeout: 3000 });
      });
    });
  }

  humanFileSize(size) {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return Number((size / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['b/s', 'kb/s', 'mb/s', 'gb/s', 'tb/s'][i];
  }


  checkPlatform(platform) {
    // return this.platform.platforms().find((platform1) => String(platform1).trim().toLowerCase() == String(platform).trim().toLowerCase());
    return this.platform.is(platform);
  }


  getWidth() {
    return this.platform.width();
  }

  getHeight() {
    return this.platform.height();
  }

}
