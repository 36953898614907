import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public subject: Subject<any> = new Subject();

  constructor() {
    console.log('Hello EventsService Provider');
  }

}
