import { ChangeDetectorRef, Component } from '@angular/core';
import { RouterEvent, NavigationEnd } from '@angular/router';
import { Platform } from '@ionic/angular';
import { filter, takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth/auth.service';
import { BluetoothService } from './services/bluetooth/bluetooth.service';
import { DatabaseService } from './services/database/database.service';
import { DeviceInfoService } from './services/device-info/device-info.service';
import { EventsService } from './services/events/events.service';
import { FcmService } from './services/fcm/fcm.service';
import { NavigationService } from './services/navigation/navigation.service';
import { UtilsService } from './services/utils/utils.service';
declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public isMobile: boolean = false;

  constructor(
    private deviceInfoService: DeviceInfoService,
    private utilsService: UtilsService,
    private authService: AuthService,
    private eventsService: EventsService,
    private fcmService: FcmService,
    private databaseService: DatabaseService,
    private platform: Platform,
    private navigationService: NavigationService,
    private cd: ChangeDetectorRef
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.navigationService.getRouter().events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((data) => {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      if (data.url === '/diagnostico') {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
      this.cd.detectChanges();
    });
    this.navigationService.navigate('root', ['/splash-screen']);
    this.eventsService.subject.subscribe((event) => {
      switch (event) {
        case 'auth:login':
        case 'auth:logout':
          this.checkLogged();
          break;
        case 'app:ready':
          this.initBeforeSplash();
          break;
      }
    });
  }

  initBeforeSplash() {
    console.log('initBeforeSplash>>>>>');
    if (!environment.production) {
      this.utilsService.presentAlert('Aplicación apuntando a pruebas', 'Aviso').finally(()=>{});
    }
    this.deviceInfoService.init();
    this.checkLogged();
  }

  checkLogged() {
    if (this.authService.isUserAuthenticated()) {
      this.authService.refreshToken().catch(() => { }).finally(() => { });
    }
  }

  async saltarAutodiagnostico() {
    return !!(await this.databaseService.get('saltarDiagnostico'));
  }

}
