import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { CanDeactivateGuard } from './guards/can-deactivate/can-deactivate.guard';
import { DiagnosticoGuard } from './guards/diagnostico/diagnostico.guard';
import { NoAuthGuard } from './guards/no-auth/no-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash-screen',
    pathMatch: 'full'
  },
  {
    path: 'diagnostico',
    canActivate: [DiagnosticoGuard],
    loadChildren: () => import('./pages/diagnostico/diagnostico.module').then( m => m.DiagnosticoPageModule)
  },
  {
    path: 'splash-screen',
    canActivate: [DiagnosticoGuard],
    loadChildren: () => import('./pages/splash-screen/splash-screen.module').then(m => m.SplashScreenPageModule)
  },
  {
    path: 'introduction',
    loadChildren: () => import('./pages/introduction/introduction.module').then(m => m.IntroductionPageModule),
    canActivate: [DiagnosticoGuard, NoAuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'empr-code',
    loadChildren: () => import('./pages/empr-code/empr-code.module').then(m => m.EmprCodePageModule),
    canActivate: [DiagnosticoGuard, NoAuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'privacy-notice',
    loadChildren: () => import('./pages/privacy-notice/privacy-notice.module').then(m => m.PrivacyNoticePageModule),
    canActivate: [DiagnosticoGuard, NoAuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [DiagnosticoGuard, NoAuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule),
    canActivate: [DiagnosticoGuard, NoAuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'recover-password',
    loadChildren: () => import('./pages/recover-password/recover-password.module').then(m => m.RecoverPasswordPageModule),
    canActivate: [DiagnosticoGuard, NoAuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [DiagnosticoGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'factor-riesgo',
    loadChildren: () => import('./pages/factor-riesgo/factor-riesgo.module').then(m => m.FactorRiesgoPageModule),
    canActivate: [DiagnosticoGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'autodiagnostico',
    loadChildren: () => import('./pages/autodiagnostico/autodiagnostico.module').then(m => m.AutodiagnosticoPageModule),
    canActivate: [DiagnosticoGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'formularios-cita',
    loadChildren: () => import('./pages/formularios-cita/formularios-cita.module').then( m => m.FormulariosCitaPageModule),
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'formularios-informacion',
    loadChildren: () => import('./pages/formularios-informacion/formularios-informacion.module').then( m => m.FormulariosInformacionPageModule),
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'permissions',
    loadChildren: () => import('./pages/permissions/permissions.module').then(m => m.PermissionsPageModule),
    canActivate: [DiagnosticoGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [DiagnosticoGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule),
    canActivate: [DiagnosticoGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
