import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import * as JWT from 'jwt-decode';
import { ApiResponseData, LoginAppleData, LoginData, LoginSocialData, LogoutData, RecoverPasswordData, RegisterData, TokenFirebaseData, UpdatePasswordData, UserAuthenticated, UserData } from 'src/app/models';
import { DeviceInfoService } from '../device-info/device-info.service';
import { UtilsService } from '../utils/utils.service';
import { BehaviorSubject } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { DatabaseService } from '../database/database.service';
declare var window: any;
declare var gapi: any;
declare var FB: any;
/**
 * En este proveedor se relacionar todo lo que
 * haga referencia a la autenticacion del usuario
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  LOCAL_TOKEN_KEY = 'token';
  USER_ID = 'user_id';
  currentUser: any = undefined;

  facebookPermissions = [
    'public_profile',
    'email',
    // 'user_gender',
    // 'user_birthday',
    // 'user_age_range'
  ];
  private authServiceState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public http: HttpClient,
    public platform: Platform,
    private deviceInfoService: DeviceInfoService,
    private databaseService: DatabaseService
  ) {
    console.log('Hello AuthService Provider');
    /**
     * cargar credenciales del usuario al cargar el provedor
     */
    this.loadUserCredentials();
  }

  /**
   * Metodo para autenticar un usuario
   * @param credencials objeto con las credenciales del usuario
   */
  login(credencials: LoginData) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.URL_AWSHOST}auth/login`, credencials)
        .subscribe(async (data: any) => {
          if (data && data.token) {
            try {
              // almacenar credenciales
              await this.storeUserCredentials(data.token);
              // respopnder promesa con el token decodificado
              resolve(JWT(data.token));
            } catch (error) {
              reject(error);
            }
          } else {
            reject('Error al iniciar sesión, intentelo de nuevo');
          }
        }, (error: HttpErrorResponse) => {
          reject(error.status === 0 ? 'Error al iniciar sesión, intentelo de nuevo' : error.statusText);
          console.error('error', error);
        });
    });
  }

  refreshToken(): Promise<UserAuthenticated> {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.URL_AWSHOST}auth/refresh-token`)
        .subscribe(async (data: any) => {
          if (data.token) {
            try {
              // Almacenar datos
              await this.storeUserCredentials(data.token);
              // Responder promesa con el la informacion del usuario decodificada
              resolve(JWT(data.token));
            } catch (error) {
              reject(error);
            }
          } else {
            reject('Error al refrescar el token, intentelo de nuevo');
          }
        }, (error: HttpErrorResponse) => {
          reject(error.status === 0 ? 'Error al refrescar el token, intentelo de nuevo' : error.statusText);
          console.error('error', error);
        });
    });
  }

  autocompleteProfile(): Promise<UserData> {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.URL_AWSHOST}autocomplete-profile`)
        .subscribe((data: any) => {
          resolve(data);
        }, (error: HttpErrorResponse) => {
          reject(error.status === 0 ? 'Error al autocompletar el perfil, intentelo de nuevo' : error.statusText);
          console.error('error', error);
        });
    });
  }

  /**
   * Metodo para guardar credenciales apple para inicio de sesion o registro.
   * @param credencials objeto con las credenciales del usuario apple
   */
  saveAppleCredentials(credencials: LoginAppleData) {
    // username='+u+'&password='+p+'&user_type='+t
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.URL_AWSHOST}auth/login-apple`, credencials)
        .subscribe((data: any) => {
          resolve(data);
        }, (error: HttpErrorResponse) => {
          reject(error.status === 0 ? 'Error al guardar credenciales, intentelo de nuevo' : error.statusText);
          console.error('error', error);
        });
    });
  }

  /**
   * Metodo para obtener credenciales apple para inicio de sesion o registro.
   * @param user_id id de usuario apple
   */
  getAppleCredentials(user_id: string): Promise<LoginAppleData> {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.URL_AWSHOST}auth/login-apple/${user_id}`)
        .subscribe((data: any) => {
          resolve(data);
        }, (error: HttpErrorResponse) => {
          reject(error.status === 0 ? 'Error al obtener credenciales, intentelo de nuevo' : error.statusText);
          console.error('error', error);
        });
    });
  }

  /**
   * Metodo para restaurar la contraseña del usuario
   * @param email
   */
  recoverPassword(data: RecoverPasswordData) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.URL_AWSHOST}auth/recover-password`, data).subscribe((data) => {
        resolve(data);
      }, (error: HttpErrorResponse) => {
        reject(error.status === 0 ? 'Error al recuperar contraseña, intentelo de nuevo' : error.statusText);
        console.error('error', error);
      });
    });
  }

  /**
   * Metdod para resgitar un usurio
   * @param params Informacion del usuario a registrar
   */
  register(registerData: RegisterData) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.URL_AWSHOST}auth/register`, registerData)
        .subscribe(async (data: UserAuthenticated) => {
          // Almacenar datos
          try {
            await this.storeUserCredentials(data.token);
            // Responder promesa con el la informacion del usuario decodificada
            resolve(JWT(data.token));
          } catch (error) {
            reject(error);
          }
        }, (error: HttpErrorResponse) => {
          reject(error.status === 0 ? 'Error al registrarse, intentelo de nuevo' : error.statusText);
          console.error('error', error);
        });
    });
  }

  loginRedesSociales(data: LoginSocialData): Promise<UserAuthenticated> {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${environment.URL_AWSHOST}auth/login-social`, data).toPromise().then(async (response: any) => {
        if (response.token) {
          try {
            await this.storeUserCredentials(response.token);
            resolve(response.token);
          } catch (error) {
            reject(error);
          }
        } else {
          resolve(null);
        }
      }).catch(async (error) => {
        if (error.status === 404) {
          resolve(null);
        } else {
          reject(error.status === 0 ? 'Error al iniciar sesión, intentelo de nuevo' : error.statusText);
        }
      });
    });
  }

  loginApple() {
    return new Promise((resolve, reject) => {
      
    });
  }

  loginGoogle() {
    return new Promise((resolve, reject) => {
      gapi.load('auth2', () => {
        const auth2 = gapi.auth2.init({
          client_id: '583291432070-h8qrp1ng3tpscpcmj74or2kutshrafph.apps.googleusercontent.com'
        });
        auth2.signIn().then(async () => {
          const data: any = {};
          const user = auth2.currentUser.get();
          const profile = user.getBasicProfile();
          const authResponse = user.getAuthResponse();

          console.log(user, profile, authResponse);

          console.log('data>>>>', data);
          const userData = {
            email: profile.getEmail(),
            last_name: profile.getFamilyName(),
            first_name: profile.getGivenName(),
            photo: profile.getImageUrl()
          };
          const loginData: LoginSocialData = {
            access_token: authResponse.id_token,
            type: 'google',
            user_id: profile.getId(),
            token_firebase: null,
            device_info: await this.deviceInfoService.deviceInfo(),
            empr_uuid: await this.databaseService.get('empr_code')
          };
          this.loginRedesSociales(loginData).then(async (data) => {
            resolve({
              userData,
              loginData: data
            });
          }).catch(async (error) => {
            reject(error);
          }).finally(() => {
          });
        }).catch((error) => {
          console.log('Error logging into google', error);
          reject('Error al iniciar sesión con google');
        });
      });
    });
  }

  loginFacebook() {
    return new Promise((resolve, reject) => {
      FB.init({
        appId: '427113648667252',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v10.0'
      });
      FB.login((res) => {
        console.log('Welcome!  Fetching your information.... ', res);
        if (res.authResponse) {
          FB.api('me?fields=id,name,first_name,last_name,email,picture.width(500).height(500)', async (data) => {
            const userData = {
              email: data.email,
              last_name: data.last_name,
              first_name: data.first_name,
              photo: data.picture.data.url
            };
            const loginData: LoginSocialData = {
              access_token: res.authResponse.accessToken,
              type: 'facebook',
              user_id: res.authResponse.userID,
              token_firebase: null,
              device_info: await this.deviceInfoService.deviceInfo(),
              empr_uuid: await this.databaseService.get('empr_code')
            };
            this.loginRedesSociales(loginData).then(async (data) => {
              resolve({
                userData,
                loginData: data
              });
            }).catch(async (error) => {
              reject(error);
            }).finally(() => {
              FB.logout((response) => { });
            });
          });
        } else {
          console.log('Error logging into Facebook');
          reject('Error al iniciar sesión con facebook');
        }
      }, { scope: 'public_profile,email' });
    });
  }

  /**
   * Metdod para cerrar la sesion activa del usuario
   */
  logout(data: LogoutData, notLive?: boolean): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (notLive) {
        this.destroyUserCredentials();
        resolve();
      } else {
        this.http.post(`${environment.URL_AWSHOST}auth/logout`, data).toPromise().then(async () => {
          // borrar todas la informacion del usuario autenticado
          this.destroyUserCredentials();
          resolve();
        }).catch(async () => {
          reject('Error al cerrar sesión, no hay conexión a internet');
        });
      }
    });
  }

  /**
   * Funcion que hace logica de guardar encuesta del usuario
   *
   */
  updateProfile(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.URL_AWSHOST}user`, data).toPromise().then(async (response: any) => {
        try {
          await this.storeUserCredentials(response.token);
          // Responder promesa con el la informacion del usuario decodificada
          resolve(JWT(response.token));
        } catch (error) {
          reject(error);
        }
      }).catch((error: HttpErrorResponse) => {
        reject(error.status === 0 ? 'Error al actualizar el perfil, intentelo de nuevo' : error.statusText);
        console.error('error', error);
      });
    });
  }

  // cambiar contraseña cliente/decorador
  updatePassword(params: UpdatePasswordData): Promise<ApiResponseData> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.URL_AWSHOST}auth/update-password`, params)
        .subscribe((data: ApiResponseData) => {
          resolve(data);
        }, (error: HttpErrorResponse) => {
          reject(error.status === 0 ? 'Error al cambiar contraseña, intentelo de nuevo' : error.statusText);
        });
    });
  }

  // Actualizar token firabse cliente/decorador
  actualizarTokenFirebase(params: TokenFirebaseData): Promise<ApiResponseData> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.URL_AWSHOST}auth/update-token-firebase`, params)
        .subscribe((data: ApiResponseData) => {
          resolve(data);
        }, (error: HttpErrorResponse) => {
          reject(error.status === 0 ? 'Error al actualizar token firebase, intentelo de nuevo' : error.statusText);
        });
    });
  }

  /**
   * Metodo para almacenar token del usuario enviado desde el servidor
   * y registrar el dispocitivo para las notificaciones push
   * @param token
   */
  storeUserCredentials(token): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.currentUser = JWT(token);
        window.localStorage.setItem(this.LOCAL_TOKEN_KEY, token);
        // window.localStorage.setItem(this.USER_ID, String(this.currentUser.id));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Destruir todas las crendicales del usuario
   */
  destroyUserCredentials() {
    this.currentUser = undefined;
    window.localStorage.clear();
  }

  /**
   * Cargar credenciales
   */
  loadUserCredentials() {
    const authToken = window.localStorage.getItem(this.LOCAL_TOKEN_KEY);
    if (authToken) {
      try {
        this.storeUserCredentials(authToken).finally(() => {
          this.authServiceState.next(true);
        });
      } catch (error) {
        console.log(error);
        this.logout(null, true).finally(() => {
          this.authServiceState.next(true);
        });
      }
    } else {
      this.authServiceState.next(true);
    }
    return;
  }

  /**
   * Retornar si esta autenticado
   */
  isUserAuthenticated() {
    return this.getUser() ? true : false;
  }

  /**
   * Obtener datos del usuario
   */
  getUser(): UserData {
    return this.currentUser;
  }

  /**
   * Observable que devuelve cuando el servicio esta listo para funcionar
   */
  onAuthServiceStateReady() {
    return this.authServiceState.pipe(skipWhile(val => val === false), take(1)).toPromise();
  }

}
