import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AppReadyService } from 'src/app/services/app-ready/app-ready.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DatabaseService } from 'src/app/services/database/database.service';
import { DeviceInfoService } from 'src/app/services/device-info/device-info.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

  constructor(
    public appReadyService: AppReadyService,
    public navigationService: NavigationService,
    public authService: AuthService,
    public deviceInfoService: DeviceInfoService,
    public databaseService: DatabaseService
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.appReadyService.onAppReady().then(async () => {
      console.log('NoAuthGuard>>>>>', state, this.authService.isUserAuthenticated());

      if (!this.authService.isUserAuthenticated()) {
        let showIntroduction = null;
        try {
          showIntroduction = await this.databaseService.get('show_introduction');
        } catch (error) {
          console.error(error);
        }
        let hasEmprCode = null;
        try {
          hasEmprCode = await this.databaseService.get('empr_code');
        } catch (error) {
          console.error(error);
        }
        let showPrivacy = null;
        try {
          showPrivacy = await this.databaseService.get('show_privacy');
        } catch (error) {
          console.error(error);
        }

        if (state.url == '/introduction' && !showIntroduction) {
          return true;
        } else if (state.url == '/empr-code' && !hasEmprCode) {
          return true;
        } else if (state.url == '/privacy-notice' && !showPrivacy) {
          return true;
        } else {
          console.log('showIntroduction>>>', showIntroduction);
          console.log('hasEmprCode>>>', hasEmprCode);
          console.log('showPrivacy>>>', showPrivacy);

          if (!showIntroduction) {
            this.navigationService.navigate('root', ['/introduction']);
            return false;
          } else if (!hasEmprCode) {
            this.navigationService.navigate('root', ['/empr-code']);
            return false;
          } else if (!showPrivacy) {
            this.navigationService.navigate('root', ['/privacy-notice']);
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.navigationService.navigate('root', ['/home']);
        return false;
      }
    });
  }

}
