import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { DatabaseService } from '../database/database.service';

@Injectable({
  providedIn: 'root'
})
export class AppReadyService {

  private appState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public platform: Platform,
    public databaseService: DatabaseService,
    public authService: AuthService
  ) {
    console.log('AppReadyService>>>>>>');
    Promise.all([
      this.platform.ready(),
      this.authService.onAuthServiceStateReady()
    ]).then(() => {
      console.log('appservice true>>>>>><');
      this.appState.next(true);
    }).catch((error) => {
      console.error(error);
    });
  }

  /**
   * Observable que devuelve cuando la app esta lista para funcionar
   */
  onAppReady() {
    return this.appState.pipe(skipWhile(val => val === false), take(1)).toPromise();
  }
}
