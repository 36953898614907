import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { RouterDirection } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    public router: Router,
    public navController: NavController,
    public ngZone: NgZone
  ) { }

  navigate(direction: RouterDirection, commands: any[], extras?: any) {
    this.ngZone.run(() => {
      this.navController.setDirection(direction);
      this.router.navigate(commands, Object.assign({ replaceUrl: direction !== 'forward' }, {
        state: extras
      }));
    });
  }

  getCurrentNavigation() {
    return this.router.getCurrentNavigation();
  }

  getRouter() {
    return this.router;
  }

}
