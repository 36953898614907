import { ErrorHandler, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import * as StackTrace from 'stacktrace-js';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService implements ErrorHandler {

  constructor(
    private platform: Platform,
    private authService: AuthService
  ) { }

  handleError(error: any): void {
    this.platform.ready().then(() => {
      const Dt = new Date().toISOString();
      if (typeof error === 'object') {
        StackTrace.fromError(error).then((trace) => {
          this.sendError(error, trace);
        });
      } else {
        this.sendError(error, null);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  async sendError(error, stackTrace) {
    try {
      console.log(error);
      if (environment.production) {
        // if (this.authService.isUserAuthenticated()) {
        //   await this.firebaseX.setCrashlyticsUserId(String(this.authService.getUser().id));
        // } else {
        //   await this.firebaseX.setCrashlyticsUserId(null);
        // }
        // await this.firebaseX.logError(error.message, stackTrace);
        // console.log('Se ha enviado error a firebase');
      }
    } catch (error) {
      console.log(error);
    }

  }
}
