export const environment = {
  production: true,
  URL_AWSHOST: 'https://ucidlmv74m.execute-api.us-east-1.amazonaws.com/prod/',
  URL_SWAGGERHOST: 'https://virtserver.swaggerhub.com/anderson-hincapie-3c/atlas-salud-inteligente/1.0.0/',
  amplitude_api_key: 'f3f4c7ac6299f8a0b0b90adee96594d3',
  URL_PRIVACY: 'https://sara.atlas.com.co/politica-de-privacidad.html',
  app_version_code: '010402',
  app_version_number: '1.4.2',
  app_package_name: 'com.tc.atlassaludinteligente',
  app_name: 'Salud inteligente'
};
