import { Injectable } from '@angular/core';
import { TokenFirebaseData } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { AppReadyService } from '../app-ready/app-ready.service';
import { AuthService } from '../auth/auth.service';
import { NavigationService } from '../navigation/navigation.service';
import { UtilsService } from '../utils/utils.service';
import * as amplitude from 'amplitude-js';
import { DeviceInfoService } from '../device-info/device-info.service';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    private authService: AuthService,
    private appReadyService: AppReadyService,
    private utilsService: UtilsService,
    private deviceInfoService: DeviceInfoService,
    private navigationService: NavigationService,
    private platform: Platform
  ) {

    this.appReadyService.onAppReady().then(async () => {
      try {
        amplitude.getInstance().init(environment.amplitude_api_key);
      } catch (error) {
        console.error(error);
      }
    });
  }

  async logEvent(eventName: string, eventProperties?: any) {
    const deviceInfo = await this.deviceInfoService.deviceInfo();
    const user = this.authService.getUser();
    const user_id = user ? String(user.id) : '';
    const account_id = user ? String(user.empr_uuid) : '';
    const device_uuid = deviceInfo ? String(deviceInfo.device_uuid) : '';
    const app_version = deviceInfo ? String(deviceInfo.app_version_number) : '';
    const first_name = user ? user.first_name : '';
    const last_name = user ? user.last_name : '';
    const amplitudeInstance = amplitude.getInstance();

    if (environment.production) {
      const userProperties = { user_id: user_id, first_name: first_name, last_name: last_name, account_id: account_id, device_uuid: device_uuid, app_version: app_version };
      console.log(userProperties, eventName, eventProperties);
      amplitudeInstance.setUserId(userProperties.user_id);
      amplitudeInstance.setGroup('account_id', userProperties.account_id);
      amplitudeInstance.setDeviceId(userProperties.device_uuid);
      amplitudeInstance.setVersionName(userProperties.app_version);
      amplitudeInstance.setUserProperties(userProperties);
      amplitudeInstance.logEvent(eventName, eventProperties);
      console.log('logging event>>>>', eventName, eventProperties);
    }
  }

}
