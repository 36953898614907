import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(
    public http: HttpClient,
    private storage: Storage
  ) {
    // console.log('Hello DatabaseProvider Provider');
  }

  get(propertyName: string) {
    return this.storage.get(propertyName);
  }

  async getJson(propertyName: string) {
    let data = await this.storage.get(propertyName);
    return data? JSON.parse(data): null;
  }

  set(propertyName: string, propertyValue: string) {
    return this.storage.set(propertyName, propertyValue);
  }

  remove(propertyName: string) {
    return this.storage.remove(propertyName);
  }

}
