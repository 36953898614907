import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AppReadyService } from 'src/app/services/app-ready/app-ready.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DatabaseService } from 'src/app/services/database/database.service';
import { DeviceInfoService } from 'src/app/services/device-info/device-info.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticoGuard implements CanActivate {

  constructor(
    public appReadyService: AppReadyService,
    public navigationService: NavigationService,
    public authService: AuthService,
    public deviceInfoService: DeviceInfoService,
    public databaseService: DatabaseService
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.appReadyService.onAppReady().then(async () => {
      console.log('DiagnosticoGuard>>>>>', state, this.authService.isUserAuthenticated());
      let saltarDiagnostico = null;
      try {
        saltarDiagnostico = await this.databaseService.get('saltarDiagnostico');
        if (!saltarDiagnostico && this.deviceInfoService.checkPlatform('ios')){
          await this.databaseService.set('saltarDiagnostico', 'true');
          saltarDiagnostico = await this.databaseService.get('saltarDiagnostico');
        }
      } catch (error) {
        console.error(error);
      }

      if (state.url !== '/diagnostico' && !saltarDiagnostico){
        this.navigationService.navigate('root', ['/diagnostico']);
        return false;
      } else if (state.url === '/diagnostico' && saltarDiagnostico) {
        this.navigationService.navigate('root', ['']);
        return false;
      } else {
        return true;
      }
    });
  }

}
