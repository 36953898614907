import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, timer, throwError, TimeoutError } from 'rxjs';
import { finalize, mergeMap, catchError, tap, retry, retryWhen, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { DatabaseService } from 'src/app/services/database/database.service';

@Injectable({
    providedIn: 'root'
})
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(private databaseService: DatabaseService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(request, next));
    }

    async handle(request: HttpRequest<any>, next: HttpHandler) {
        const started = Date.now();
        let ok: string;
        // add authorization header with jwt accessToken if available
        if (this.includes(request.url)) {
            let headers: any = {};
            let params: any = {};
            // se agrega codigo de empresa en los query params de la solicitud http
            try {
                let emprUuid = await this.databaseService.get('empr_code');
                if (emprUuid) {
                    params['empr_uuid'] = emprUuid;
                }
            } catch (error) { }
            // se agrega token de usuario en el header de la solicitud http
            if (localStorage.getItem('token')) {
                headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
            }

            request = request.clone({
                setHeaders: headers,
                setParams: params
            });
        }
        return next.handle(request).pipe(
            // timeout(10000),
            mergeMap((event: HttpEvent<any>) => {
                console.log(event);
                if (event instanceof HttpResponse && event.status == 200 && (event.body == null || event.body == undefined)) {
                    return throwError(new HttpErrorResponse({ status: 500, statusText: 'Error al enviar la solicitud, intentelo mas tarde', url: event.url }));
                } else {
                    return of(event);
                }
            }),
            retryWhen(this.genericRetryStrategy()),
            catchError((err: any) => {
                console.log('error', err);
                if (err.url && this.includes(err.url) && err.status === 0) {
                    return throwError(new HttpErrorResponse({ status: err.status, statusText: 'Error al enviar la solicitud, intentelo mas tarde', url: err.url }));
                } else if (err && err.url && this.includes(err.url) && err.status === 401) {
                    return throwError(new HttpErrorResponse({ status: err.status, statusText: err.error.message, url: err.url, error: err.error }));
                } else if (err.url && this.includes(err.url) && err.error?.message) {
                    return throwError(new HttpErrorResponse({ status: err.status, statusText: err.error.message, url: err.url, error: err.error }));
                } else if (err.status === 0 || err.name == 'TimeoutError') {
                    return throwError(new HttpErrorResponse({ status: err.status, statusText: 'Error al enviar la solicitud, intentelo mas tarde', url: err.url }));
                } else {
                    return throwError(err);
                }
            }),
            tap(
                // Succeeds when there is a response; ignore other events
                event => ok = event instanceof HttpResponse ? 'succeeded' : '',
                // Operation failed; error is an HttpErrorResponse
                () => ok = 'failed'
            ),
            // Log when response observable either completes or errors
            finalize(() => {
                const elapsed = Date.now() - started;
                const msg = `${request.method} "${request.urlWithParams}" ${ok} in ${elapsed} ms.`;
                // console.log(msg);
                // // console.log('request', request);
            })
        ).toPromise();
    }

    private includes(url: string) {
        return url.includes(environment.URL_AWSHOST);
    }

    genericRetryStrategy = ({
        maxRetryAttempts = 1,
        scalingDuration = 2,
        excludedStatusCodes = []
    }: {
        maxRetryAttempts?: number,
        scalingDuration?: number,
        excludedStatusCodes?: number[]
    } = {}) => (attempts: Observable<any>) => {
        return attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;
                console.log({
                    retryAttempt
                })
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (
                    !(error.url && this.includes(error.url)) ||
                    retryAttempt > maxRetryAttempts ||
                    excludedStatusCodes.find(e => e === error.status)
                ) {
                    console.log('returning>>>>>');
                    return throwError(error);
                }
                // // console.log(
                //   `Attempt ${retryAttempt}: retrying in ${retryAttempt *
                //     scalingDuration}ms`
                // );
                // retry after 1s, 2s, etc...
                return timer(retryAttempt * 1000 * scalingDuration);
            }),
            finalize(() => { })
        );
    }
}
