import { ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage';

// google maps module
import { AgmCoreModule } from '@agm/core';

// native plugins
// pages
import { AppComponent } from './app.component';

// interceptors
import { AppHttpInterceptor } from './interceptors/app-http/app-http.interceptor';

// importar locales
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { SharedModule } from './modules/shared/shared.module';
import { ErrorHandlingService } from './services/error-handling/error-handling.service';
// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');

// hammerjs
import * as Hammer from 'hammerjs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  } as any;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC4DDYWODCzuBJs7yVD9ieSyG9rkVP5tUk',
      libraries: ['places']
    }),
    IonicModule.forRoot({
      backButtonText: '',
      mode: 'ios'
    }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    IonicStorageModule.forRoot(),
    HammerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlingService },
    // gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    // native plugins
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
